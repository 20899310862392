import './src/assets/css/open-iconic-bootstrap.min.css'
import './src/assets/css/animate.css'
import './src/assets/css/owl.carousel.min.css'
import './src/assets/css/owl.theme.default.min.css'
import './src/assets/css/magnific-popup.css'
import './src/assets/css/aos.css'
import './src/assets/css/ionicons.min.css'
import './src/assets/css/bootstrap-datepicker.css'
import './src/assets/css/jquery.timepicker.css'
import './src/assets/css/flaticon.css'
import './src/assets/css/icomoon.css'
import './src/assets/css/style.css'
import './src/assets/css/custom.css'

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
        `This application has been updated. ` +
        `Reload to display the latest version?`
    )
    if (answer === true) {
        window.location.reload()
    }
}